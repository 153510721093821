@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: local('Inter'), url(modules/common/assets/fonts/Inter-ExtraLight-slnt=0.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: local('Inter'), url(modules/common/assets/fonts/Inter-Light-slnt=0.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'), url(modules/common/assets/fonts/Inter-Regular-slnt=0.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter'), url(modules/common/assets/fonts/Inter-Medium-slnt=0.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url(modules/common/assets/fonts/Inter-SemiBold-slnt=0.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: local('Inter'), url(modules/common/assets/fonts/Inter-Bold-slnt=0.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: rgba(239, 240, 241, 0.4);
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  min-height: 100%;
  position: relative;
}

.styledTooltip .ant-tooltip-inner {
  border-radius: 16px;
  background: rgba(74, 74, 74, 1);
  font-size: 12px;
  color: #ffffff;
  padding: 16px 24px;
  opacity: 0.95;
  font-weight: 400;
}

.styledTooltip .ant-tooltip-arrow-content {
  width: 10px;
  height: 10px;
  background: rgba(74, 74, 74, 1);
  opacity: 0.95;
}

.clubViewTooltip .ant-tooltip-inner {
  border-radius: 8px;
  background: rgba(74, 74, 74, 1);
  font-size: 14px;
  color: #ffffff;
  padding: 10px;
  opacity: 0.95;
  font-weight: 400;
  word-break: break-all;
}

.clubViewTooltip .ant-tooltip-arrow-content {
  width: 10px;
  height: 10px;
  background: rgba(74, 74, 74, 1);
  opacity: 0.95;
}

.followPopoverTooltip {
  z-index: 99999;
}

.followPopoverTooltip .ant-tooltip-inner {
  border-radius: 8px;
  background: rgba(74, 74, 74, 1);
  font-size: 12px;
  color: #ffffff;
  padding: 8px;
  opacity: 0.95;
  font-weight: 400;
  word-break: break-word;
  text-align: center;
}

.followPopoverTooltip .ant-tooltip-arrow-content {
  width: 10px;
  height: 10px;
  background: rgba(74, 74, 74, 1);
  opacity: 0.95;
}

.teamPopover .ant-popover-inner {
  border-radius: 8px;
  padding: 22px 16px;
}

.teamPopover .ant-popover-inner-content {
  padding: 0;
}

.commentReaction .ant-popover-inner {
  padding: 12px 14px;
  background: rgba(17, 17, 17, 0.7);
  border-radius: 8px;
}

.commentReaction .ant-popover-inner-content {
  padding: 0;
}

.commentReaction .ant-popover-arrow {
  display: none;
}

.followPopover .ant-popover-inner {
  border-radius: 8px;
  padding: 16px;
}

.followPopover .ant-popover-inner-content {
  padding: 0;
}

.followPopover .ant-popover-arrow {
  width: 12px;
  height: 12px;
  border-width: 8px;
  bottom: 3.2px !important;
}

.menuHeaderPopover .ant-popover-inner {
  border-radius: 8px;
  padding: 10px;
}

.menuHeaderPopover .ant-popover-inner-content {
  padding: 0;
}

.menuHeaderPopover .ant-popover-arrow {
  width: 20px;
  height: 20px;
  border-width: 8px;
  bottom: 3.2px !important;
  top: 0 !important;
  background: #fff;
}

.notificationsPopover .ant-popover-inner {
  border-radius: 8px;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
}

.notificationsPopover .ant-popover-inner-content {
  padding: 0;
}

.notificationsPopover .ant-popover-arrow {
  width: 5px;
  height: 5px;
  border-width: 5px;
  top: 5px !important;
  background: #fff;
}

.globalSearch .ant-popover-inner {
  padding: 16px;
  border-radius: 8px;
}

.globalSearch .ant-popover-inner-content {
  padding: 0;
}

code {
  font-family: Inter, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.tox-collection__group {
  padding: 16px !important;
}

.tox-autocompleter {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;
  background: #ffffff !important;
}
.tox-collection__item-label {
  font-family: 'Inter' !important;
  font-weight: 600 !important;
  padding: 5px !important;
}
.tox-menu.tox-collection.tox-collection--list {
  border: 0 !important;
  min-width: 260px !important;
}
.tox-collection__item-icon {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
  border: 1px solid #eee;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}

.commentMentionFullname {
  margin-left: 16px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #111111 !important;
}

.commentMentionPositionTitle {
  margin-left: 16px !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #6b778c !important;
  padding-bottom: 16px !important;
  border-bottom: 1px solid #eff0f1 !important;
  width: 100% !important;
}

.tox-collection__item--active {
  background: none !important;
}

.commentMentionAvatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100% !important;
}

.post_mention_icon {
  width: 30px !important;
}
.post_mention {
  cursor: pointer;
  color: #1890ff;
}

.followNotifyAccepted {
  background: #1890ff;
}

.followNotifyAccepted .ant-notification-notice-icon {
  display: none;
}

.followNotifyAccepted .ant-notification-notice-message {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
}

.followNotifyDeclined {
  background: #f5222d;
}

.followNotifyDeclined .ant-notification-notice-icon {
  display: none;
}

.followNotifyDeclined .ant-notification-notice-message {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
}

.ant-mentions-dropdown-menu-item-active {
  background: #ecf6ff;
}

.ant-mentions-dropdown-menu-item:hover {
  background: #ecf6ff !important;
}

.clubViewSearch {
  padding-top: 0 !important;
  margin-top: -5px;
  width: 100%;
  max-width: 600px;
}

.clubViewSearch .ant-popover-arrow {
  display: none;
}

.clubViewSearch .ant-popover-inner {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px;
}

.clubViewSearch .ant-popover-inner-content {
  padding: 0;
}
@media (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
  .show-on-mobile {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .show-on-mobile {
    display: none !important;
  }
}

